import React from 'react'
import { PropTypes } from 'prop-types'
import { is, isEmpty } from 'ramda'
import MfePage from '../MfePage'

const defaultProperties = {
  useGlobalHeader: true,
  hideLanguageSelector: true,
  showOrganizationForm: true,
  useBottomSaveBar: true,
  displaySeparateLabel: true,
  displayAdditionalTabInfoText: true,
  formMaxWidth: '456px',
  themeDensity: 'high',
  alignConfirmationModalFooter: 'start'
}

const AccountProfile = (props) => {
  const accountProfileMfeProps = props?.properties?.accountProfileMfeProps

  return (
    <MfePage
      mfeProps={{
        ...props,
        component: '@jarvis/react-account-profile',
        properties: {
          ...(!is(Object, accountProfileMfeProps) ||
          isEmpty(accountProfileMfeProps)
            ? defaultProperties
            : accountProfileMfeProps)
        }
      }}
    />
  )
}

AccountProfile.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any)
}

AccountProfile.defaultProps = {
  properties: {}
}

export default AccountProfile
