import React from 'react'
import PropTypes from 'prop-types'
import { IconChevronRight } from '@veneer/core/'
import {
  StyledCard,
  ChevronRightWrapper,
  Content,
  Description,
  Image
} from './styles'

const Tile = ({ dataTestId, description, imageUrl, onClick, title }) => (
  <StyledCard
    content={
      <Content data-testid={`${dataTestId}-tile-content`}>
        <Image data-testid={`${dataTestId}-tile-image`}>
          <img
            src={`${imageUrl}`}
            alt={title}
            data-testid={`${dataTestId}-tile-img`}
          />
        </Image>
        <Description data-testid={`${dataTestId}-tile-description`}>
          <div
            className="subtitle-regular"
            data-testid={`${dataTestId}-tile-description-title`}
          >
            {title}
          </div>
          <div
            className="caption"
            data-testid={`${dataTestId}-tile-description-text`}
          >
            {description}
          </div>
        </Description>
        <ChevronRightWrapper data-testid={`${dataTestId}-tile-chevron-wrapper`}>
          <IconChevronRight
            size={24}
            data-testid={`${dataTestId}-tile-chevron-icon`}
          />
        </ChevronRightWrapper>
      </Content>
    }
    data-testid={dataTestId}
    hoverable
    onClick={onClick}
  />
)

Tile.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

export default Tile
