import React, { useCallback } from 'react'
import { MicroFrontend } from '@jarvis/react-mfe-component'

export const ThirdPartyIDPSettings = ({
  navigation,
  nodeId,
  basePath,
  mfe,
  ...rest
}) => {
  const SubMfe = useCallback(
    ({ component, navigation, nodeId, basePath, type, mfe }) => {
      return (
        <MicroFrontend
          disableContainerPadding
          {...rest}
          {...{
            component,
            navigation,
            nodeId,
            basePath,
            type,
            mfe
          }}
        />
      )
    },
    [rest]
  )

  return (
    <SubMfe
      type="third-party-idp-settings"
      props={rest}
      navigation={navigation}
      nodeId={nodeId}
      basePath={basePath}
      mfe={mfe}
      component="@jarvis/react-ecp-third-party-idp-settings"
    />
  )
}
