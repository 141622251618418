import primitives from '@veneer/primitives'

const CustomBreakpoints = {
  xx: '1680px'
}

const mediaQueries = {
  xs: `(max-width: ${primitives.layout.breakpoint0}px)`,
  sm: `(min-width: ${primitives.layout.breakpoint1}px)`,
  md: `(min-width: ${primitives.layout.breakpoint3}px)`,
  lg: `(min-width: ${primitives.layout.breakpoint5}px)`,
  xl: `(min-width: ${primitives.layout.breakpoint7}px)`,
  xx: `(min-width: ${CustomBreakpoints.xx})`
}

export default mediaQueries
