import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Content = styled.div`
  font-family: ${primitives.typography.family0};
  #profile-form {
    margin-top: ${primitives.layout.size0}px !important;
  }
`

export default Content
