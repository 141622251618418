import mediaQueries from './styles'

export const md = (rules) =>
  `
  @media ${mediaQueries.md} {
    ${rules}
  }
  `

export const xx = (rules) =>
  `
  @media ${mediaQueries.xx} {
    ${rules}
  }
  `
