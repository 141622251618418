import React from 'react'
import PropTypes from 'prop-types'

import { MFELoader } from '@jarvis/react-portal-addons'

const MfePage = ({ mfeProps }) => (
  <>
    <MFELoader {...mfeProps} />
  </>
)

MfePage.propTypes = {
  breadcrumbsPageLabelId: PropTypes.string.isRequired,
  mfeProps: PropTypes.objectOf(PropTypes.any).isRequired
}

export default MfePage
