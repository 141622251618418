import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colorBackground};
  column-gap: ${(props) => props.theme.orbitalSix};
  display: grid;
  grid-template-rows: min-content min-content min-content auto;
  grid-template-columns: repeat(12, 1fr);
  padding: 0 ${(props) => props.theme.orbitalSix};
  > * {
    grid-column: 1 / -1;
  }
  margin-top: 20px;
`

export const NavigationContainer = styled.div``

export const SettingsContainer = styled.div`
  gap: ${(props) => props.theme.orbitalSix};
  display: flex;
  justify-content: space-between;
  > div {
    width: 100%;
  }
`

export const LoaderWrapper = styled.div``

export const ThirdPartyIDPContainer = styled.div`
  padding: 24px 24px 32px;
  height: auto;
  width: calc(100% - 48px);
`

export default Container
