import React, { useEffect, useState, useCallback } from 'react'
import { MicroFrontend } from '@jarvis/react-mfe-component'
import { ThemeProvider } from '@veneer/theme'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import AccountProfilePage from 'src/pages/AccountProfile'
import SettingsPage from 'src/pages/Settings'
import AccountSettings from 'src/pages/AccountSettings'
import { Content } from 'src/styles'
import { getRolePermID } from 'src/utils/api'
import { basePath } from 'src/utils/constants'
import useShellRootContext from 'src/contexts/ShellRoot/useShellRootContext'

const App = (props) => {
  const { t = (_key, defaultValue) => defaultValue, interfaces } =
    useShellRootContext?.() || {}

  const authProvider = interfaces?.v2?.authProvider
  const authStack = interfaces?.v1?.app?.getAuthStack?.() || 0
  const breadcrumbs = interfaces?.v1?.breadcrumbs || props?.mockBreadcrumbs
  const featureFlags = interfaces?.v1?.featureFlags
  const mockProp = props?.mockProp
  const navigation = interfaces?.v1?.navigation || props?.mockNavigation

  const [organizationInfo, setOrganizationInfo] = useState('')
  const [showSettingsButtonFlag, setShowSettingsButtonFlag] = useState(
    mockProp ? true : false
  )

  useEffect(() => {
    const getFeatureFlag = async () => {
      const client = await featureFlags?.getClient?.('ecp')

      const featureFlag = await client?.getFeatureFlag?.({
        key: 'ecp-3id',
        defaultValue: false
      })

      setShowSettingsButtonFlag(featureFlag)
    }

    getFeatureFlag()
  }, [featureFlags, showSettingsButtonFlag])

  useEffect(() => {
    const getOrgDetails = async () => {
      const activeUserDetails = await getRolePermID(authProvider, authStack)
      const orgDetails = activeUserDetails?.data?.tenantResourceId
      setOrganizationInfo(orgDetails)
    }

    getOrgDetails()

    global.System.import('@jarvis/react-account-profile')
  }, [authProvider, authStack, setOrganizationInfo])

  const renderAccountProfilePage = <AccountProfilePage {...props} />

  const renderAccountSettings = (
    <AccountSettings
      {...props}
      mfe="account"
      navigation={navigation}
      nodeId={organizationInfo}
      basePath={basePath}
      data-testid="third-party-idp-div-style"
    />
  )

  const renderSettings = (
    <SettingsPage
      {...props}
      showSettingsButtonFlag={showSettingsButtonFlag}
    />
  )

  const SubMfe = useCallback(
    ({ component, type, title }) => (
      <MicroFrontend
        {...{
          component,
          type,
          title
        }}
      />
    ),
    []
  )

  const [pageTitle, setPageTitle] = useState(
    t('ecp-settings.account', 'Account')
  )

  useEffect(() => {
    const accountProfileTitle = () => {
      breadcrumbs?.add?.({
        key: 'account-profile',
        text: t('ecp-settings.accountProfile', 'Account Profile'),
        translationKey: 'string',
        url: undefined
      })
      setPageTitle(t('ecp-settings.accountProfile', 'Account Profile'))
    }

    const handleHeaderTitle = () => {
      if (
        navigation?.location?.pathname?.includes?.('/account/account-profile')
      ) {
        accountProfileTitle()
      } else if (
        navigation?.location?.pathname?.includes?.('/account/settings')
      ) {
        navigation?.push?.('/account')
      }
    }

    handleHeaderTitle()

    navigation?.listen?.((location) => {
      if (location?.pathname === '/account/account-profile') {
        breadcrumbs?.add({
          key: 'account-profile',
          text: t('ecp-settings.accountProfile', 'Account Profile'),
          translationKey: 'string',
          url: undefined
        })
        setPageTitle(t('ecp-settings.accountProfile', 'Account Profile'))
      } else if (location?.pathname === '/account/settings') {
        breadcrumbs?.add({
          key: 'settings',
          text: t('ecp-settings.settings', 'Settings'),
          translationKey: 'string',
          url: undefined
        })
        setPageTitle(t('ecp-settings.settings', 'Settings'))
      } else {
        breadcrumbs?.remove?.('account-profile')
        breadcrumbs?.remove?.('settings')
        setPageTitle(t('ecp-settings.account', 'Account'))
      }
    })
  }, [breadcrumbs, navigation, t])

  return (
    <ThemeProvider>
      <SubMfe
        type="headerTitle"
        title={pageTitle}
        component="@jarvis/react-ecp-header"
      />
      <Content>
        <Router>
          <Route
            render={() => (
              <Switch>
                <Route
                  exact
                  path="*/account/account-profile"
                  render={() => renderAccountProfilePage}
                />
                <Route
                  path="*/account/settings"
                  render={() => renderAccountSettings}
                />
                <Route
                  path="*/account"
                  render={() => renderSettings}
                />
              </Switch>
            )}
          />
        </Router>
      </Content>
    </ThemeProvider>
  )
}

export default App
