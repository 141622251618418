import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import Tile from './Tile'
import AccountProfileImg from '../../assets/images/account-profile.svg'
import PreferencesImg from '../../assets/images/preferences.svg'
import { Container, SettingsContainer } from './styles'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext.ts'

const Settings = (props) => {
  const { t = (_key, defaultValue) => defaultValue, interfaces } =
    useShellRootContext?.() || {}
  const navigation = interfaces?.v1?.navigation
  const showSettingsButtonFlag = props?.showSettingsButtonFlag

  const theme = useTheme()

  const handleAccountProfileClick = () => {
    navigation?.push?.('/account/account-profile')
  }

  return (
    <ThemeProvider theme={theme}>
      <Container
        className="body"
        data-testid="settings"
      >
        <SettingsContainer>
          <Tile
            dataTestId="setting-account-profile"
            description={t(
              'ecp-settings.accountProfileDescr',
              'Manage profile details. Change account password.'
            )}
            imageUrl={`${AccountProfileImg}`}
            onClick={handleAccountProfileClick}
            title={t('ecp-settings.accountProfile', 'Account Profile')}
          />
          {showSettingsButtonFlag && (
            <Tile
              dataTestId="setting-preferences"
              description={t(
                'ecp-settings.settingsDescr',
                'Manage user settings. Set option values.'
              )}
              imageUrl={`${PreferencesImg}`}
              onClick={() => {
                navigation?.push?.('/account/settings')
              }}
              title={t('ecp-settings.settings', 'Settings')}
            />
          )}
        </SettingsContainer>
      </Container>
    </ThemeProvider>
  )
}

export default Settings
