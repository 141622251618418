const LANGUAGE_CODES = [
  'bg_BG',
  'cs_CZ',
  'da_DK',
  'de_AT',
  'de_CH',
  'de_DE',
  'de_LI',
  'de_LU',
  'el_CY',
  'el_GR',
  'en_AD',
  'en_AE',
  'en_AF',
  'en_AG',
  'en_AI',
  'en_AL',
  'en_AM',
  'en_AU',
  'en_AW',
  'en_AZ',
  'en_BA',
  'en_BB',
  'en_BD',
  'en_BH',
  'en_BN',
  'en_BS',
  'en_BT',
  'en_BW',
  'en_BZ',
  'en_CA',
  'en_CM',
  'en_CR',
  'en_CY',
  'en_DZ',
  'en_EG',
  'en_ET',
  'en_FJ',
  'en_GB',
  'en_GD',
  'en_GE',
  'en_GH',
  'en_HK',
  'en_ID',
  'en_IE',
  'en_IL',
  'en_IN',
  'en_IS',
  'en_JM',
  'en_JO',
  'en_JP',
  'en_KE',
  'en_KH',
  'en_KN',
  'en_KW',
  'en_KY',
  'en_LA',
  'en_LB',
  'en_LC',
  'en_LK',
  'en_LY',
  'en_MA',
  'en_ME',
  'en_MK',
  'en_MM',
  'en_MS',
  'en_MT',
  'en_MU',
  'en_MV',
  'en_MY',
  'en_NA',
  'en_NG',
  'en_NP',
  'en_NZ',
  'en_OM',
  'en_PG',
  'en_PH',
  'en_PK',
  'en_PR',
  'en_QA',
  'en_RS',
  'en_RW',
  'en_SA',
  'en_SG',
  'en_SX',
  'en_TC',
  'en_TH',
  'en_TJ',
  'en_TL',
  'en_TM',
  'en_TN',
  'en_TT',
  'en_TW',
  'en_TZ',
  'en_UG',
  'en_US',
  'en_VC',
  'en_VG',
  'en_VI',
  'en_VN',
  'en_WS',
  'en_ZA',
  'en_ZM',
  'es_AR',
  'es_BO',
  'es_CL',
  'es_CO',
  'es_CU',
  'es_DO',
  'es_EC',
  'es_ES',
  'es_GT',
  'es_HN',
  'es_MX',
  'es_NI',
  'es_PA',
  'es_PE',
  'es_PY',
  'es_SV',
  'es_UY',
  'es_VE',
  'et_EE',
  'fi_FI',
  'fr_BE',
  'fr_BJ',
  'fr_BL',
  'fr_CA',
  'fr_CH',
  'fr_CI',
  'fr_FR',
  'fr_GF',
  'fr_GP',
  'fr_HT',
  'fr_LC',
  'fr_LU',
  'fr_MC',
  'fr_MF',
  'fr_MG',
  'fr_MQ',
  'fr_NC',
  'fr_PF',
  'fr_RE',
  'fr_SN',
  'fr_YT',
  'hr_HR',
  'hu_HU',
  'it_CH',
  'it_IT',
  'it_SM',
  'it_VA',
  'ja_JP',
  'ko_KR',
  'lt_LT',
  'lv_LV',
  'nb_NO',
  'nl_BE',
  'nl_BQ',
  'nl_CW',
  'nl_NL',
  'pl_PL',
  'pt_AO',
  'pt_BR',
  'pt_MO',
  'pt_MZ',
  'pt_PT',
  'ro_MD',
  'ro_RO',
  'ru_BY',
  'ru_KZ',
  'ru_RU',
  'ru_UA',
  'ru_UZ',
  'sk_SK',
  'sl_SI',
  'sv_FI',
  'sv_SE',
  'tr_TR',
  'zh_CN',
  'zh_HK',
  'zh_MO',
  'zh_SG',
  'zh_TW'
]

export const DOMAIN_REGEX = new RegExp(
  /^@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
)
export const basePath = '/account'

export default LANGUAGE_CODES
