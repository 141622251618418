import React from 'react'
import styled from 'styled-components'
import { Card } from '@veneer/core/'
import { md, xx } from '../../../styles/mixins'

export const StyledCard = styled((props) => <Card {...props} />)`
  border: none;
  cursor: pointer;
  grid-column: span 12;
  padding: ${(props) => props.theme.orbitalFour};
  user-select: none;

  ${md(`
    grid-column: span 6;
  `)}

  ${xx(`
    grid-column: span 4;
  `)}
`

export const ChevronRightWrapper = styled.div`
  align-items: center;
  display: flex;
  padding-left: ${(props) => props.theme.orbitalThree};
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`

export const Description = styled.div`
  flex: 1 1 auto;
  padding-left: ${(props) => props.theme.orbitalTwo};
`

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
`
