import React from 'react'
import { ThirdPartyIDPSettings } from 'src/shared-components/ThirdPartyIDPSettings/index'
import * as S from 'src/components/Settings/styles'

const AccountSettings = (props) => {
  return (
    <S.ThirdPartyIDPContainer>
      <ThirdPartyIDPSettings {...props} />
    </S.ThirdPartyIDPContainer>
  )
}

export default AccountSettings
