import { UserTenantMgtSvcClient } from '@jarvis/web-stratus-client'

export const getRolePermID = async (authProvider, authStack) => {
  const userMgtClient = new UserTenantMgtSvcClient(
    authStack,
    authProvider?.createOrgedAuthProvider?.()
  )
  const activeUser = await userMgtClient?.getCurrentActiveUser?.()
  return activeUser
}
